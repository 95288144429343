<template>
  <div class="port">
    <div class="port-info">
      <div class="port-info__create">
        <base-button @click="newPortServer(network, tariff.id, formatPort)">
          {{ $t('newPort') }}
        </base-button>
      </div>
    </div>
    <transition name="bubble">
      <!--      <div v-if="loading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <!--      <page-block v-else class="table">-->
      <page-block class="table">
        <tariffs-table-port :dataset="ports" :type="'server'" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import TariffsTablePort from '@/layouts/Stack/pages/Main/components/TariffsTablePort';
import newPortServer from '@/layouts/Stack/mixins/newPortServer';

export default {
  name: 'ServerPort',
  components: {
    TariffsTablePort,
  },
  mixins: [providerChange, newPortServer],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatPort: [],
    };
  },
  computed: {
    networkId() {
      return this.tariff.id;
    },
    network() {
      return this.$store.state.moduleStack.networks.filter(x => x.shared === false);
    },
    floatingIp() {
      return this.$store.state.moduleStack.floatingips.filter(x => x.port_id === null);
    },

    ports() {
      const ports = this.$store.getters['moduleStack/GET_SORTED_LIST_PORTS'].filter(
        x => x.device_id === this.tariff.id
      );
      // return ports.sort((a, b) => {
      // сортировка по IP
      // if (
      //   a.fixed_ips[0] &&
      //   a.fixed_ips[0].ip_address &&
      //   b.fixed_ips[0] &&
      //   b.fixed_ips[0].ip_address
      // ) {
      //   const numA = Number(
      //     a.fixed_ips[0].ip_address
      //       .split('.')
      //       .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
      //       .reduce((a, v) => ((a += v), a), 0)
      //   );
      //   const numB = Number(
      //     b.fixed_ips[0].ip_address
      //       .split('.')
      //       .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
      //       .reduce((a, v) => ((a += v), a), 0)
      //   );
      //   return numA - numB;
      // } else
      return ports;
      // });
    },
  },
  watch: {
    ports: {
      // собираем информацию о текущих портах
      handler: function (event) {
        const newPorts = [];
        event.forEach(port => {
          newPorts.push({
            id: port.id,
            subnetId: port.fixed_ips[0].subnet_id,
            fixed_ips: port.fixed_ips,
          });
        });
        this.formatPort = newPorts;
      },
      immediate: true,
    },
  },
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "attach": "Подключен",
    "size": "Размер",
    "type": "Тип диска",
    "os": "Источник",
    "name": "Тип конфигурации",
    "cpu": "vCPU",
    "ram": "Память",
    "newPort": "Создать порт",
    "sure": {
      "confirm": "Создать",
      "add": "Добавить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.table {
  margin-top: 0.5rem;
}
.port {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 0.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

}
</style>
