import NewPortServer from '@/layouts/Stack/components/NewPortServer.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      device_id: '',
      data: {},
      type: '1',
      timerId: '',
      portId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    newPortServer: function (instance, device, port) {
      // console.log(instance, device);
      // console.log(port);
      if (device) this.device_id = device;
      const that = this;
      return new Promise(() => {
        const selfName = 'newPortServer';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewPortServer,
          closeOnBackdrop: false,
          props: {
            net: instance.id,
            instance: instance,
            device: device,
            port: port,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            type: data => {
              data === '1'
                ? Vue.set(this.modal.footer.confirm.props, 'title', this.$t('sure.confirm'))
                : Vue.set(this.modal.footer.confirm.props, 'title', this.$t('sure.add'));
            },
            notready: () => {
              instance = {};
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  // ветвление на создание нового порта или обновление существующего
                  if (typeof instance === 'object') {
                    if (instance.fixed_ips) {
                      const addedIp = instance.port.fixed_ips[0];
                      delete addedIp.device_id;
                      instance.fixed_ips.push(addedIp);
                      this.updatePort(instance)
                        .then(async () => {
                          this.fetchServer();
                          that.$modals.close();
                          this.showResModal('Порт успешно обновлен.');
                        })
                        .catch(e => {
                          this.$modals.close({ name: 'newPortServer' });
                          console.log(e);
                          if (e.data && e.data.computeFault && e.data.computeFault.message) {
                            this.deleteCurrentPort(this.portId).then();
                            this.showError(e.data.computeFault.message);
                          } else this.showError(e);
                        });
                    } else {
                      this.addNewPort(instance)
                        .then(async data => {
                          this.portId = data.port.id;
                          this.addNewPortToServer(this.portId)
                            .then(async () => {
                              this.fetchServer();
                              that.$modals.close();
                              this.showResModal('Порт успешно создан.');
                            })
                            .catch(e => {
                              console.log(e);
                              this.$modals.close();
                              if (e.data && e.data.computeFault && e.data.computeFault.message) {
                                this.deleteCurrentPort(this.portId).then();
                                this.showError(e.data.computeFault.message);
                              } else this.showError(e);
                            });
                        })
                        .catch(e => {
                          this.$modals.close({ name: 'newPortServer' });
                          console.log(e);
                          if (e.data && e.data.computeFault && e.data.computeFault.message) {
                            this.deleteCurrentPort(this.portId).then();
                            this.showError(e.data.computeFault.message);
                          } else this.showError(e);
                        });
                    }
                  } else {
                    this.portId = instance;
                    this.addNewPortToServer(this.portId)
                      .then(async () => {
                        this.fetchServer();
                        that.$modals.close();
                        this.showResModal('Порт успешно подключён.');
                      })
                      .catch(e => {
                        console.log(e);
                        this.$modals.close();
                        if (e.data && e.data.computeFault && e.data.computeFault.message) {
                          this.deleteCurrentPort(this.portId).then();
                          this.showError(e.data.computeFault.message);
                        } else this.showError(e);
                      });
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewPort(payload) {
      return this.$store.dispatch('moduleStack/createPort', payload).catch(e => {
        // console.log('1');
        console.log(e);
        this.showError(e);
      });
    },
    updatePort(payload) {
      const params = {
        port: {
          fixed_ips: payload.fixed_ips,
        },
        id: payload.portId,
        item: 'fixed_ips',
      };
      return this.$store.dispatch('moduleStack/updatePort', params).catch(e => {
        // console.log('1');
        console.log(e);
        this.showError(e);
      });
    },
    deleteCurrentPort(port) {
      return this.$store.dispatch('moduleStack/deletePort', {
        port: port,
      });
    },
    addNewPortToServer(payload) {
      const params = {
        payload: payload,
        device_id: this.device_id,
      };
      return this.$store.dispatch('moduleStack/createPortToServer', params);
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
